module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","background_color":"#ffffff","theme_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"74d9b0dbf7d7e77e17541647433c3f2a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-static-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":true,"htmlTitle":"Content Manager"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
